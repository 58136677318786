var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"data-cy":"740","fluid":""}},[_c('v-row',{attrs:{"data-cy":"741"}},[_c('v-col',{staticClass:"col-md-2 col-10 mb-4",attrs:{"data-cy":"742"}},[_c('v-combobox',{ref:"input_search",attrs:{"data-cy":"743","label":_vm.$t('88'),"items":_vm.itemsForSearch,"append-icon":"mdi-magnify","single-line":"","hide-details":"","multiple":"","chips":"","deletable-chips":"","outlined":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1),(_vm.isCustomRolesEnabled)?_c('v-col',{attrs:{"data-cy":"6130"}},[_c('v-btn',{ref:"add_role_button",staticClass:"float-right mt-6",attrs:{"data-cy":"6131","color":"primary"},on:{"click":function($event){_vm.showAddRoleDialog = true}}},[_c('v-icon',{attrs:{"data-cy":"6132","left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t("1975")))],1)],1):_vm._e()],1),(_vm.showAddRoleDialog)?_c('v-dialog',{ref:"add_role_dialog",attrs:{"data-cy":"6133","value":_vm.showAddRoleDialog,"width":"80%","persistent":""}},[_c('v-card',{staticClass:"py-2",attrs:{"data-cy":"6134"}},[_c('v-card-title',{attrs:{"data-cy":"6135"}},[_vm._v(_vm._s(_vm.$t("1857")))]),_c('v-card-text',{attrs:{"data-cy":"6136"}},[_c('v-row',{staticClass:"no-gutters",attrs:{"data-cy":"6137"}},[_c('v-col',{staticClass:"col-md-6",attrs:{"data-cy":"6138"}},[_c('themis-input',{ref:"themis_input_role_name",attrs:{"data-cy":"6139","p-name":"1858","p-label-class":"secondary--text","p-label":_vm._f("required")(_vm.$t('1858')),"p-rules":("required|duplicate:" + _vm.isRoleNameDuplicate + "|max:" + _vm.customRoleNameCharacterLimit)},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-text-field',{ref:"role_name",attrs:{"data-cy":"6140","color":"secondary","background-color":"white","clear-icon":"mdi-close-circle-outline","placeholder":_vm.$t('1858'),"error-messages":errors,"outlined":"","clearable":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", $$v)},expression:"role.name"}})]}}],null,false,2091573777)})],1)],1),_c('v-row',{staticClass:"no-gutters",attrs:{"data-cy":"6141"}},[_c('v-col',{attrs:{"data-cy":"6142"}},[_c('themis-input',{ref:"themis_input_role_description",attrs:{"data-cy":"6143","p-name":"1859","p-label-class":"secondary--text","p-label":_vm._f("required")(_vm.$t('1859')),"p-rules":("required|max:" + _vm.customRoleDescriptionCharacterLimit)},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-textarea',{ref:"role_description",attrs:{"data-cy":"6144","color":"secondary","background-color":"white","clear-icon":"mdi-close-circle-outline","rows":"1","placeholder":_vm.$t('1859'),"error-messages":errors,"outlined":"","clearable":"","auto-grow":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.role.description),callback:function ($$v) {_vm.$set(_vm.role, "description", $$v)},expression:"role.description"}})]}}],null,false,926802987)})],1)],1),_c('v-data-table',{ref:"table_permissions",staticClass:"elevation-1 table--add-role-permissions",attrs:{"data-cy":"6145","item-key":"permission","checkbox-color":"primary","selectable-key":"choosable","items":_vm.allPermissions,"headers":_vm.headersForAddRoleAndPermissionsTable,"items-per-page":-1,"hide-default-footer":"","fixed-header":"","show-select":""},on:{"item-selected":_vm.onRowSelection},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('span')]}},{key:"item.permission",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-cy":"6247"}}),_vm._v(_vm._s(_vm.getDisplayNameForPermission(item.permission)))]}}],null,false,1653919159),model:{value:(_vm.permissionsToBeAdded),callback:function ($$v) {_vm.permissionsToBeAdded=$$v},expression:"permissionsToBeAdded"}})],1),_c('v-card-actions',{attrs:{"data-cy":"6146"}},[_c('v-row',{staticClass:"justify-space-between px-1 py-2",attrs:{"data-cy":"6147"}},[_c('v-col',{staticClass:"text-right",attrs:{"data-cy":"6148"}},[_c('v-btn',{ref:"cancel_add_role_button",staticClass:"mr-6",attrs:{"data-cy":"6149","text":""},on:{"click":_vm.handleCancelAddRole}},[_vm._v(_vm._s(_vm.$t("1860")))]),_c('v-btn',{ref:"save_add_role_button",attrs:{"data-cy":"6150","color":"primary","loading":_vm.isAddingRole,"disabled":_vm.disableRoleAdd},on:{"click":_vm.handleAddRole}},[_vm._v(_vm._s(_vm.$t("1861")))])],1)],1)],1)],1)],1):_vm._e(),_c('v-data-table',{ref:"table_roles",staticClass:"elevation-1",attrs:{"data-cy":"744","item-key":"id","items":_vm.rolesForTable,"search":_vm.search,"headers":_vm.headersForTable,"loading-text":_vm.$t(_vm.$TABLES.ROLES.loadingText),"no-data-text":_vm.$t(_vm.$TABLES.ROLES.noDataText),"no-results-text":_vm.$t(_vm.$TABLES.ROLES.noResultText, {searchQuery: _vm.search}),"footer-props":_vm.footersForTable,"items-per-page":_vm.$TABLES.ROLES.itemsPerPage,"custom-filter":_vm.customFilterForTable,"fixed-header":"","multi-sort":""},on:{"click:row":function (item) { return _vm.handleRoleRowClick(item); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }